import React, { useState } from "react";
import Calendar from "react-calendar";
import { getDate } from "./../../slices/todolist";
import { useDispatch } from "react-redux";
const OpenCalendar = ({ setAnchorEl }) => {
  const [dateValue, setDateValue] = useState(new Date());
  const tileDisabled = ({ date, view }) => {
    const today = new Date();
    // Disable dates that are earlier than the current date, but not the current date itself
    return (
      view === "month" && date < today && date.getDate() !== today.getDate()
    );
  };

  const dispatch = useDispatch();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <Calendar
      tileDisabled={tileDisabled}
      activeStartDate={dateValue}
      locale={localStorage.getItem("i18nextLng")}
      defaultActiveStartDate={dateValue}
      maxDetail="month"
      view="month"
      minDate={new Date("2023")}
      navigationLabel={({ date }) => {
        return localStorage.getItem("i18nextLng") === "ar"
          ? `${date.getFullYear()}  ${date.toLocaleString(
              localStorage.getItem("i18nextLng"),
              {
                month: "long",
              }
            )} ${date.getDate()}`
          : ` ${date.getDate()} ${date.toLocaleString(
              localStorage.getItem("i18nextLng"),
              {
                month: "long",
              }
            )} ${date.getFullYear()}`;
      }}
      prev2Label={null}
      next2Label={null}
      onChange={setDateValue}
      value={dateValue}
      onClickDay={(value) => {
        dispatch(
          getDate(new Date(value).toLocaleDateString(undefined, options))
        );
        setDateValue(value);
        setAnchorEl(null);
      }}
      onActiveStartDateChange={() => {
        setDateValue(null);
      }}
    />
  );
};

export default OpenCalendar;
